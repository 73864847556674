<template>
  <div class="addRoom">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>编辑客房</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- -------------表单----------- -->
    <el-form label-width="120px">
      <el-form-item label="客房名称">
        <el-input v-model="formList.roomName"></el-input>
      </el-form-item>
      <el-form-item label="所属酒店">
        <el-select v-model="formList.hotelId" placeholder="请选择">
          <el-option
            v-for="item in hotelLists"
            :key="item.cateId"
            :label="item.cateName"
            :value="item.cateId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="床型" class="col">
        <el-input v-model="formList.bedType"></el-input>
      </el-form-item>
      <el-form-item label="面积" class="col">
        <el-input v-model="formList.area">
          <template slot="append">平米</template>
        </el-input>
      </el-form-item>
      <el-form-item label="楼层" class="col1">
        <el-input v-model="formList.floor">
          <template slot="append">楼</template>
        </el-input>
      </el-form-item>
      <el-form-item label="人数" class="col1">
        <el-input v-model.number="formList.maxCount">
          <template slot="append">人</template>
        </el-input>
      </el-form-item>
      <el-form-item label="是否退订">
        <el-radio v-model="radio" label="1">可退订</el-radio>
        <el-radio v-model="radio" label="0">不可退订</el-radio>
      </el-form-item>
      <el-form-item label="是否改期">
        <el-radio v-model="formList.changeInfo" :label="1">是</el-radio>
        <el-radio v-model="formList.changeInfo" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="是否享受会员权益">
        <el-radio v-model="formList.isUser" :label="1">是</el-radio>
        <el-radio v-model="formList.isUser" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="是否显示">
        <el-radio v-model="formList.isShow" :label="1">是</el-radio>
        <el-radio v-model="formList.isShow" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="万旅网是否显示">
        <el-radio v-model="formList.wlShow" :label="1">是</el-radio>
        <el-radio v-model="formList.wlShow" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="是否仅限新用户购买">
        <el-radio v-model="formList.isNew" :label="1">是</el-radio>
        <el-radio v-model="formList.isNew" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="销售平台">
        <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0"></div>
        <el-checkbox-group v-model="checked" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="item in platform" :label="item.value" :key="item.value">{{
            item.name
          }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="供应商">
        <el-select v-model="formList.supplierId" placeholder="请选择">
          <el-option
            v-for="item in supplierOptions"
            :key="item.cateId"
            :label="item.cateName"
            :value="item.cateId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="结算价">
        <el-input v-model="formList.settlePrice" placeholder="请输入结算价"></el-input>
      </el-form-item>
      <el-form-item label="支付方式">
        <el-select v-model="formList.sellWay" placeholder="请选择">
          <el-option
            v-for="item in sellWayList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="游戏ID">
        <el-input
          v-model="formList.gameId"
          placeholder="购票成功后是否参与游戏活动  0 为不参与"
        >
        </el-input>
      </el-form-item>

      <el-form-item label="所需积分" v-show="formList.sellWay != 1 ? true : false">
        <el-input
          @input="onInput1"
          v-model.number="integral"
          placeholder="请输入积分"
        ></el-input>
      </el-form-item>
      <el-form-item label="展示图" class="showImg">
        <!-- v-if="formList.showImg !== ''" -->
        <send-image
          :images="formList.showImg"
          type="one"
          @addImageStr="addImageStr"
        ></send-image>

        <span style="color: red">每张图片不能超过500K，建议尺寸：172*115px</span>
      </el-form-item>
      <el-form-item label="轮播图" class="showImg">
        <send-image
          :images="roomImg.join(',')"
          type="more"
          @addImageStrs="addImageStrs"
        ></send-image>

        <span style="color: red; margin-left: 20px"
          >每张图片不能超过500K，建议尺寸：345*188px</span
        >
      </el-form-item>
      <el-form-item label="设施设备">
        <el-input
          type="textarea"
          :rows="5"
          placeholder="请输入内容"
          v-model="formList.facility"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="客房简介">
        <el-input
          type="textarea"
          :rows="5"
          placeholder="请输入内容"
          v-model="formList.remark"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="费用明细">
        <el-input
          type="textarea"
          :rows="5"
          placeholder="请输入内容"
          v-model="formList.expend"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <div class="btn">
      <el-button type="primary" @click="onSave">保存</el-button>
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>
const platform = [
  { value: 1, name: "小程序" },
  { value: 2, name: "H5" },
  { value: 3, name: "IOS" },
  { value: 4, name: "Android" },
  { value: 5, name: "PC" },
];
import { sendFile } from "../../api/sendFile";
import { allHotel, hotelList } from "../../api/hotelList";
import { addRoom, roomDetails } from "../../api/room";
import { supList } from "../../api/supList.js";
import sendImage from "../../components/sendImage.vue";
import { couponAllCateAPI } from "../../api/coupon";
export default {
  components: { sendImage },
  name: "editRoom",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      supplierOptions: [{ cateId: 0, cateName: "自营" }],
      checkAll: false,
      checked: [],
      checkVal: [],
      platform: platform,
      radio: null,
      roomImg: [],
      hotelLists: [],
      sellWayList: [
        {
          value: 1,
          label: "全金额",
        },
        {
          value: 2,
          label: "全积分",
        },
        {
          value: 3,
          label: "积分+金额",
        },
      ],
      formList: {
        roomName: "",
        hotelId: null,
        bedType: "",
        area: "",
        floor: "",
        maxCount: null,
        showImg: "",
        roomImg: "",
        facility: "",
        remark: "",
        expend: "",
        sellWay: null,
        gameId: "",
        sellPlace: "0",
        settlePrice: "",
        supplierId: "",
        isShow: 1,
        isUser: 0,
        changeInfo: 1,
        wlShow:1,
        isNew:0
      },
      unsubscribe: null,
      integral: null,
    };
  },
  watch: {
    radio: function () {
      this.unsubscribe = Number(this.radio);
    },
    roomImg: function () {
      this.formList.roomImg = this.roomImg.join(",");
    },
  },
  created() {
    this.getHotelList();
    this.getRoomInfo();
    this.getSupplierList();
    this.init();
    // this.getCate()
  },
  methods: {
    getSupplierList() {
      supList().then((res) => {
        this.supplierOptions.push(...res.data.data);
      });
    },
    async getCate() {
      const { data } = await couponAllCateAPI();
      let arr = data.data;
      arr.map((e) => {
        this.supplierOptions.push({ supplierId: e.cateId, label: e.cateName });
      });
    },
    init() {
      for (let i = 0; i < this.platform.length; i++) {
        this.checkVal.push(this.platform[i].value);
      }
    },
    handleCheckAllChange(val) {
      this.checkVal = [];
      if (val) {
        this.init();
        console.log("if", this.checkVal);
        this.checked = this.checkVal;
        this.formList.sellPlace = "0";
      } else {
        this.checked = [];
      }
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.platform.length;
      this.formList.sellPlace = this.checked.toString();
      console.log(value, "value");
    },
    onInput1(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value)) {
        this.$message({
          type: "error",
          message: "请输入整数!",
        });
        this.integral = "";
        return false;
      }
    },
    // 获取客房信息
    async getRoomInfo() {
      const { data } = await roomDetails({
        roomId: this.id,
      });
      this.radio = data.data[0].unsubscribe + "";
      this.roomImg = data.data[0].roomImg;
      this.formList.sellPlace = data.data[0].sellPlace;
      try {
        let arr = data.data[0].sellPlace.split(",");
        let newArr = [];
        arr.map((e) => {
          newArr.push(Number(e));
        });
        if (newArr[0] == 0 || newArr.length == this.platform.length) {
          this.checkAll = !this.checkAll;
          console.log(this.checkVal, "val");
          this.checked = this.checkVal;
        } else {
          this.checkVal = newArr;
          this.checked = this.checkVal;
        }
      } catch (e) {
        console.log(e);
      }
      this.formList.roomName = data.data[0].roomName;
      this.formList.hotelId = data.data[0].hotelId;
      this.formList.bedType = data.data[0].bedType;
      this.formList.area = data.data[0].area;
      this.formList.floor = data.data[0].floor;
      this.formList.maxCount = data.data[0].maxCount;
      this.formList.showImg = data.data[0].showImg;
      this.formList.facility = data.data[0].facility;
      this.formList.remark = data.data[0].remark;
      this.formList.expend = data.data[0].expend;
      this.formList.sellWay = data.data[0].sellWay;
      this.formList.gameId = data.data[0].gameId;
      this.formList.changeInfo = data.data[0].changeInfo;
      this.formList.isUser = data.data[0].isUser || 0;
      this.formList.wlShow = data.data[0].wlShow || 0;
      this.formList.isNew = data.data[0].isNew || 0;

      this.integral = data.data[0].integral;
      this.formList.supplierId = data.data[0].supplierId;
      this.formList.settlePrice = data.data[0].settlePrice;
      this.formList.isShow = data.data[0].isShow;
      //  this.formList = data.data[0]
    },
    async onSave() {
      for (const key in this.formList) {
        if (this.formList[key] === null || this.formList[key] === "") {
          return this.$message({
            message: "请把数据填写完整",
            type: "error",
          });
        }
      }
      if (this.formList.sellWay != 1 && this.integral == null) {
        return this.$message({
          message: "在全积分或者积分+金额情况下,所需积分为必填项",
          type: "error",
        });
      }
      this.formList.roomId = Number(this.id);
      const { data } = await addRoom({
        ...this.formList,
        unsubscribe: this.unsubscribe,
        integral: this.integral,
      });
      if (data.code == 0) {
        console.log(data);
        this.$message({
          message: "编辑客房成功",
          type: "success",
        });
        this.$router.go(-1);
      } else {
        this.$message({
          message: data.msg,
          type: "error",
        });
      }
    },
    async getHotelList() {
      const { data } = await allHotel({
        currentPage: 1,
        pageSize: 1000,
      });
      this.hotelLists = data.data;
    },
    addImageStr(image) {
      this.formList.showImg = image;
    },
    addImageStrs(image) {
      this.roomImg = image;
    },
    onSendImg() {
      this.$refs.fileRefs.click();
    },
    onClose() {
      this.formList.showImg = "";
    },
    async onFileChange(event) {
      var files = event.target.files;
      if (files && files.length > 0) {
        var file = files[0];
        var formData = new FormData();
        formData.append("file", file);
        const { data } = await sendFile(formData);
        console.log();
        this.formList.showImg = data.data[0].src;
      }
    },
    onSendImg1() {
      this.$refs.fileRefs1.click();
    },
    onClose1(item) {
      this.roomImg = this.roomImg.filter((value) => {
        return value != item;
      });
    },
    async onFileChange1(event) {
      var files = event.target.files;
      if (files && files.length > 0) {
        var file = files[0];
        var formData = new FormData();
        formData.append("file", file);
        const { data } = await sendFile(formData);
        if (this.roomImg.length < 6) {
          this.roomImg.push(data.data[0].src);
        } else {
          this.$message({
            showClose: true,
            message: "最多只能添加6张图片哦",
            type: "warning",
          });
          return;
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.addRoom {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  /deep/ .el-form {
    margin-top: 50px;
    width: 800px;
    .col,
    .col1 {
      display: inline-block;
      .el-form-item__content {
        width: 200px;
      }
    }
    .el-form-item__content {
      width: 500px;
    }
    .showImg {
      .el-form-item__content {
        display: flex;
        flex-wrap: wrap;

        .img {
          position: relative;
          height: 100px;
          width: 100px;
          margin: 20px 20px;
          img {
            height: 100px;
            width: 100px;
            border: 1px solid #c0ccda;
          }
          div {
            position: absolute;
            top: 0;
            right: 2px;
            height: 10px;
            width: 10px;
            z-index: 100;
          }
        }
        .addImg {
          display: inline-block;
          width: 100px;
          height: 100px;
          border: 1px dashed #c0ccda;
          text-align: center;
          line-height: 100px;
          cursor: pointer;
          margin-top: 20px;
          i {
            width: 50px;
            height: 50px;
            font-size: 20px;
            color: #c0ccda;
          }
        }
      }
    }
  }
  .btn {
    width: 200px;
  }
}
</style>
